/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import * as React from 'react';
import ContainOverflow from '../../AZCustomComponent/ContainOverflow';
import { PreBakedHero } from '@/features/home';
import styles from './styles.module.scss';

const HeroPrebackedGeneric = ({ keyProp, contentItem }: { keyProp: string; contentItem: any }) => (
  <ContainOverflow key={keyProp}>
    <div className={styles.heroPreBakedImg}>
      <PreBakedHero blockItem={contentItem} tabindex={0} />
    </div>
  </ContainOverflow>
);

export default HeroPrebackedGeneric;
