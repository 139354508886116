/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useMemo } from 'react';
import cx from 'classnames';
import { Grid } from '../../../Grid';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import homepageStyles from '../styles.module.scss';
import { Legal } from '../../../AZCustomComponent/Legal';
import { getImagePaths } from '@/utils/getImagePaths';
import { useEagerLoad } from '@/hooks/useEagerLoad';
import { Picture } from '@/components/Picture/Picture';

type Props = {
  adGridSize: number;
  content: any;
};

export const PrebakedSplitContent = ({ adGridSize, content }: Props) => {
  const eager = useEagerLoad({
    isEagerLoadDesktop: content.isEagerLoadDesktop,
    isEagerLoadMobile: content.isEagerLoadMobile,
    isEagerLoadTablet: content.isEagerLoadTablet,
  });

  const imageData = useMemo(() => {
    const images = getImagePaths(content.imagePath);
    return {
      mobile: {
        src: images.phoneImage,
        /*
          These sizes are averages of the actual image sizes for different breakpoints.
          They are used to provide a default aspect ratio placeholder until the image
          is loaded by the browser to avoid a layout shift.
        */
        width: 1376,
        height: 550,
      },
      tablet: {
        src: images.tabletImage,
        width: 2220,
        height: 275,
      },
      desktop: {
        src: images.desktopImage,
        width: 2332,
        height: 270,
      },
    };
  }, [content.imagePath]);

  const imageExists = !!content.imagePath;
  const imageLink = content.imageLink;
  const imageIsLink = imageExists && !!imageLink;
  const ImageContainer = imageIsLink ? Link : 'div';
  const imageContainerProps: any = imageIsLink
    ? {
        to: imageLink,
      }
    : {};

  if (imageExists) {
    return (
      <Grid item xs={12} md={adGridSize}>
        <ImageContainer className={homepageStyles.imageContainer} {...imageContainerProps}>
          <Picture
            mobile={imageData.mobile}
            tablet={imageData.tablet}
            desktop={imageData.desktop}
            altText={content.altText}
            /*
             * because we have the bad image in order to have the layout formatted correctly,
             * we need to add the onError function to hide the broken image/red box on firefox and ie
             */
            // @ts-expect-error investigate if style is always there
            onError={(e) => (e.target.style.display = 'none')}
            priority={eager}
            loading={eager ? 'eager' : 'lazy'}
          />
        </ImageContainer>
        {content.heading && (
          <h3
            className={cx(
              homepageStyles.splitHeadline,
              azCommonStyles['az-title-1-regular'],
              azCommonStyles['az-margin-top-xxs'],
              azCommonStyles['az-margin-bottom-xxs']
            )}
            dangerouslySetInnerHTML={{ __html: content.heading }}
          />
        )}
        <Legal content={content} />
      </Grid>
    );
  }
  return null;
};

export default PrebakedSplitContent;
