/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { memo } from 'react';
import NextImage from '@/components/NextImage';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { useResponsiveImage } from '@/hooks/useResponsiveImage';
import { SmartLink as Link } from '@/utils/smartLink';
import homepageStyles from './styles.module.scss';
import { trackHomeCarouselInteraction } from '@/utils/analytics/track/trackHomeCarouselInteraction';
import type { HeroPreBaked } from '@/types/legacy';

type Props = {
  blockItem: HeroPreBaked;
  itemIndex?: number;
  styleOverride?: React.CSSProperties;
  tabindex?: number;
  linkEnabled?: boolean;
  setFocusToAnchorTag?: boolean;
};

const PreBakedHero = memo<Props>(function PreBakedHero(props: Props) {
  const { setFocusToAnchorTag, blockItem, itemIndex = 0, linkEnabled = true } = props;
  const responsiveImagePath = useResponsiveImage(blockItem.imagePath);
  const imagePath = responsiveImagePath;

  const onBannerBtnClick = (bannerName: string, slideNumber: number) => {
    trackHomeCarouselInteraction(bannerName, slideNumber, `Home Hero Banner: Rank ${slideNumber}`);
  };

  const bannerStyle = {
    width: '100%',
    display: 'block',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  };
  const deviceClassName = {
    desktop: blockItem.desktop || false,
    tablet: blockItem.tablet || false,
    mobile: blockItem.mobile || false,
  };
  const bannerImg = imagePath ? (
    <div className={homepageStyles.prebakedImageBackground}>
      <NextImage
        key={props.itemIndex}
        aria-label={blockItem.altText}
        src={imagePath}
        alt=""
        priority={itemIndex === 0}
        fill
        className={homepageStyles.heroImage}
      />
    </div>
  ) : null;
  return (
    <div style={bannerStyle} data-testid="preBakedHero">
      <DeviceWrapper {...deviceClassName}>
        <div
          data-testid="bannerContainer"
          className={homepageStyles.bannerContainer}
          style={props.styleOverride ? props.styleOverride : undefined}
          onClick={() => onBannerBtnClick(blockItem.altText, itemIndex)}
        >
          {blockItem.imageLink && linkEnabled ? (
            <Link
              className={homepageStyles.bannerImageLink}
              to={blockItem.imageLink}
              setFocusToAnchorTag={setFocusToAnchorTag}
            >
              {bannerImg}
            </Link>
          ) : (
            bannerImg
          )}
        </div>
      </DeviceWrapper>
    </div>
  );
});

export default PreBakedHero;
