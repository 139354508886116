/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Grid } from '../../../Grid';
import { SmartLink as Link } from '../../../../utils/smartLink';
import Styles from '../styles.module.scss';
import { useResponsiveImage } from '../../../../hooks/useResponsiveImage';
import { useEagerLoad } from '../../../../hooks/useEagerLoad';

type Props = {
  adGridSize: number;
  content: any;
};

export const IconSplitContent = ({
  adGridSize,
  content: { isEagerLoadDesktop, isEagerLoadMobile, isEagerLoadTablet, ...content },
}: Props) => {
  const imageUrl = useResponsiveImage(content.imagePath);
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });

  return (
    content && (
      <Grid
        xs={12}
        item
        md={adGridSize}
        className={(Styles.iconSplitContainer, Styles.gridContainer)}
      >
        <Link className={Styles.iconContentDisplay} to={content.ctaLink1 || '#'}>
          <img
            loading={eager ? 'eager' : 'lazy'}
            className={Styles.image}
            src={imageUrl}
            alt={''}
          />
          <div className={Styles.descriptionContainer}>
            {content.heading && (
              <div className={Styles.title} dangerouslySetInnerHTML={{ __html: content.heading }} />
            )}
            {content.subHeading && (
              <div
                className={Styles.subTitle}
                dangerouslySetInnerHTML={{ __html: content.subHeading }}
              />
            )}
          </div>
        </Link>
      </Grid>
    )
  );
};

export default IconSplitContent;
