/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Grid } from '../../Grid';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { SmartLink as Link } from '../../../utils/smartLink';
import cx from 'classnames';
import homepageStyles from './styles.module.scss';
import { Button } from '@/components/Button';
import ImageTextSplitContent from './SplitContent/ImageTextSplitContent';
import { Legal } from '@/components/AZCustomComponent/Legal';
import ImageBakedSplitContent from './SplitContent/ImageBakedSplitContent';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type AlignType = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';

type BlockItemData = {
  name: string;
  adContent: any;
  ctaButtonName: string;
  ctaButtonURL: string;
  legalText: string;
  legalLink: string;
  legalLinkName: string;
  legalCopy: string;
  titleHeaderUnderline: boolean;
  overAllHeadLine: string;
  overAllHeadLineAlign: AlignType;
  overAllContentAlign: 'left' | 'center' | 'right';
  itemsPerRowInDesktop: number;
  itemsPerRowInTablet: number;
  itemsPerRowInMobile: number;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
};

type Props = {
  blockItemData: BlockItemData;
};

const SplitContentBlockV2 = ({ blockItemData }: Props) => {
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const matchesDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const matchesTablet =
    useMediaQuery((theme) => theme.breakpoints.only('md')) || (!matchesMobile && !matchesDesktop);

  const itemWidth = matchesMobile
    ? blockItemData.itemsPerRowInMobile
    : matchesTablet
    ? blockItemData.itemsPerRowInTablet
    : blockItemData.itemsPerRowInDesktop;

  const getTemplateview = (content: any, index: number) => {
    if (content['@type'] === 'ImageBakedContent') {
      return (
        <ImageBakedSplitContent
          content={content}
          key={`${content.name}${index}`}
          itemWidth={itemWidth}
          index={index}
        />
      );
    }

    if (content['@type'] === 'ImageTextContent') {
      return (
        <ImageTextSplitContent
          content={content}
          key={`${content.name}${index}`}
          itemWidth={itemWidth}
          index={index}
        />
      );
    }
  };

  const renderSplitView = (blockItemData: BlockItemData) => {
    const contentBlock =
      (blockItemData.adContent?.length && blockItemData.adContent[0].contents) ||
      blockItemData.adContent;
    // @ts-expect-error refine type
    return contentBlock?.map((valuesplit, index) => getTemplateview(valuesplit, index));
  };

  const overAllHeadLine = (blockItemData?.overAllHeadLine || '').trim();
  const overAllHeadLineAlign = (blockItemData?.overAllHeadLineAlign || 'left').trim();
  const splitBlockCTA = blockItemData?.ctaButtonName && blockItemData?.ctaButtonURL;
  const overAllContentAlign =
    blockItemData?.overAllContentAlign === 'left'
      ? 'flex-start'
      : blockItemData?.overAllContentAlign === 'center'
      ? 'center'
      : blockItemData?.overAllContentAlign === 'right'
      ? 'flex-end'
      : 'flex-start';

  return (
    <DeviceWrapper
      desktop={blockItemData?.desktop || false}
      tablet={blockItemData?.tablet || false}
      mobile={blockItemData?.mobile || false}
    >
      <Grid
        className={homepageStyles.splitContentBlock}
        container
        data-testid="splitContentBlockV2"
        spacing={0}
      >
        {overAllHeadLine ? (
          <>
            <Grid item xs={12}>
              <h2
                style={{ textAlign: overAllHeadLineAlign as AlignType }}
                className={cx(
                  !blockItemData.titleHeaderUnderline ? homepageStyles.pageTitleWithPadding : null,
                  homepageStyles.pageTitle
                )}
                dangerouslySetInnerHTML={{
                  __html: overAllHeadLine,
                }}
              />
            </Grid>
            {blockItemData.titleHeaderUnderline && (
              <Grid item xs={12} className={homepageStyles.separatorItem}>
                <div className={`${homepageStyles.separator}`} />
              </Grid>
            )}
          </>
        ) : null}
        <Grid
          container
          style={{
            justifyContent: overAllContentAlign,
          }}
          className={cx(homepageStyles.splitContentBlockContainer)}
        >
          {renderSplitView(blockItemData)}
        </Grid>
        {splitBlockCTA ? (
          <Grid item className={homepageStyles.ctaGridContainer}>
            <Link to={blockItemData.ctaButtonURL} samePage className={homepageStyles.ctaLink}>
              <Button customClass={homepageStyles.ctaButton} variant="outlined" fakeButton={true}>
                {blockItemData.ctaButtonName}
              </Button>
            </Link>
          </Grid>
        ) : null}
        <Legal
          overrideClassName={homepageStyles.legalTextWrapperSplitBlock}
          content={blockItemData}
        />
      </Grid>
    </DeviceWrapper>
  );
};

export default SplitContentBlockV2;
