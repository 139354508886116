/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { PureComponent } from 'react';
import cx from 'classnames';
import { Grid } from '@/components/Grid';
import { SmartLink as Link } from '@/utils/smartLink';
import DynamicContent from '@/components/DynamicContent';
import azCommonStyles from '@/theme/globals.module.scss';
import { Button } from '@/components/Button';
import { BREAKPOINTS } from '@/constants/screen';
import styles from './styles.module.scss';
import { trackHomeCarouselInteraction } from '@/utils/analytics/track/trackHomeCarouselInteraction';

type Props = {
  altText: string;
  heading: string;
  subHeading: string;
  body: string;
  ctaLink1: string;
  ctaLink2: string;
  ctaName1: string;
  ctaName2: string;
  foreGroundImage: string;
  itemIndex: string;
  themeCode: string;
  layoutCode: string;
};

type State = {
  width: number | null;
  reverseContent: boolean;
  resizing: boolean;
};

class LEFTH2C3 extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: null,
      resizing: false,
      reverseContent: true,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateDimensions);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateDimensions);
    }
  }

  onBannerBtnClick = (bannerName: string | null, slideNumber: number) => {
    const name = bannerName ?? '';
    trackHomeCarouselInteraction(name, slideNumber, `Home Hero Banner: Rank ${slideNumber}`);
  };

  onResize = () => {
    const { resizing } = this.state;

    if (resizing) {
      this.setState({
        resizing: !resizing,
      });
    }
  };
  updateDimensions = () => {
    this.setState({
      width: document.documentElement.clientWidth,
      resizing: true,
    });

    if (
      this.state.resizing &&
      this.state.width !== null &&
      this.state.width < BREAKPOINTS.TABLET_MIN
    ) {
      this.setState({
        reverseContent: true,
      });
    }
  };
  renderImage = () => {
    const { foreGroundImage, altText } = this.props;
    const imgUrl = foreGroundImage || '';
    const mdImgUrl = imgUrl.replace(/(.png)/, '-t.png');
    return (
      <div className={styles.imageBlockWrapper}>
        {imgUrl && (
          <picture>
            <source srcSet={mdImgUrl} media="(max-width: 719px)" />
            <source srcSet={mdImgUrl} media="(max-width: 1139px)" />
            <img loading="lazy" className={styles.imageblock} src={imgUrl} alt={altText} />
          </picture>
        )}
      </div>
    );
  };
  renderCTA = (
    ctaName: string,
    ctaLink: string,
    heading: string,
    itemIndex: string,
    primary: boolean,
    themeCode: string
  ) => {
    let formattedBannerText: string | null = '';
    let currentItemIndex = 0;

    if (typeof global.document !== 'undefined') {
      const headerText = heading;
      const temporalDivElement = document.createElement('div');
      temporalDivElement.innerHTML = headerText;
      formattedBannerText = temporalDivElement.textContent;

      if (itemIndex) {
        currentItemIndex = Number(itemIndex) + 1;
      }
    }

    return (
      ctaName && (
        <Link
          to={ctaLink || '#'}
          tabIndex={-1}
          onClick={() => this.onBannerBtnClick(formattedBannerText, currentItemIndex)}
        >
          <Button
            aria-label={ctaName}
            variant={primary ? 'contained' : 'outlined'}
            className={
              primary
                ? cx(
                    azCommonStyles['az-button-text'],
                    themeCode === 'Dark' ? styles.primaryLight : styles.primaryCTA
                  )
                : cx(
                    azCommonStyles['az-button-text'],
                    themeCode === 'Dark' ? styles.secondaryLight : styles.secondaryCTA,
                    styles.btnText
                  )
            }
            role="button"
          >
            {ctaName}
          </Button>
        </Link>
      )
    );
  };

  render() {
    const { reverseContent } = this.state;
    const {
      heading = '',
      subHeading = '',
      body = '',
      ctaName1,
      ctaName2,
      ctaLink1: primaryCTALink = '#',
      ctaLink2: secondaryCTALink = '#',
      themeCode,
      itemIndex,
    } = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="row"
        className={
          reverseContent
            ? `${styles.heroContentBlockReverse} ${styles.heroContentBlock}`
            : `${styles.heroContentBlock}`
        }
      >
        <Grid item xs={12} md={5}>
          {this.renderImage()}
        </Grid>
        <Grid item xs={12} md={7} className={styles.mobileBackground}>
          <div className={styles.content}>
            <DynamicContent
              tagName="div"
              attrs={{
                className: `
                     ${themeCode === 'Dark' ? styles.headLine : styles.lightHeadLine}`,
              }}
              innerHtml={heading || ''}
            />
            <DynamicContent
              tagName="div"
              attrs={{
                className: cx(
                  azCommonStyles['az-subtitle-1-medium'],
                  azCommonStyles['az-margin-top-xxxs'],
                  themeCode === 'Dark' ? styles.subHeadLine : styles.subLightHeadLine
                ),
              }}
              innerHtml={subHeading || ''}
            />
            <DynamicContent
              tagName="div"
              attrs={{
                className: cx(
                  azCommonStyles['az-body-2-regular'],
                  azCommonStyles['az-margin-top-4xs'],
                  azCommonStyles['az-margin-bottom-4xs'],
                  themeCode === 'Dark' ? styles.bodyDarkTheme : styles.bodyLightTheme
                ),
              }}
              innerHtml={body || ''}
            />
            <div className={styles.cta}>
              {this.renderCTA(ctaName1, primaryCTALink, heading, itemIndex, true, themeCode)}
              {this.renderCTA(ctaName2, secondaryCTALink, heading, itemIndex, false, themeCode)}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default LEFTH2C3;
