/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import { Legal } from '../../../AZCustomComponent/Legal';
import { Button } from '@/components/Button';
import homepageStyles from '../styles.module.scss';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  content: any;
  index: number;
  itemWidth: number;
};

export const ImageTextSplitContent = ({ content, index, itemWidth }: Props) => {
  const backgroundItem = content.backgroundColor
    ? content.backgroundColor
    : content.imageBackgroundURL
    ? `url("${content.imageBackgroundURL}")`
    : 'none';
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const customClass = itemWidth > 1 && matchesMobile ? homepageStyles.ctaButton : '';
  const hasCTAButton = content.ctaButtonName && content.ctaButtonURL;

  return content ? (
    <div
      style={{ width: `calc(${100 / itemWidth}% - 18px)` }}
      key={`${content.name}${index}`}
      data-testid={`image-text-content-${content.name.toLowerCase().replace(/\s/g, '-')}-${index}`}
      className={hasCTAButton ? homepageStyles.imageBakedContainer : undefined}
    >
      <div className={cx(azCommonStyles['az-padding-xs'])} style={{ background: backgroundItem }}>
        {content.content && <div dangerouslySetInnerHTML={{ __html: content.content }} />}
        {hasCTAButton && (
          <div
            className={cx(
              azCommonStyles['az-margin-top-xxs'],
              itemWidth > 1 ? homepageStyles.imageBakedCTAButton : ''
            )}
          >
            <Link
              to={content.ctaButtonURL}
              samePage
              ariaLabel={`${content.altText} ${content.ctaButtonName}`}
            >
              <Button customClass={customClass} variant="contained" fakeButton={true}>
                {content.ctaButtonName}
              </Button>
            </Link>
          </div>
        )}
      </div>
      <div>
        <Legal overrideClassName={homepageStyles.legalTextWrapperItem} content={content} />
      </div>
    </div>
  ) : null;
};

export default ImageTextSplitContent;
