/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { Grid } from '../../Grid';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import PrebakedSplitContent from './SplitContent/PrebakedSplitContent';
import HorizontalSplitContent from './SplitContent/HorizontalSplitContent';
import VerticalSplitContent from './SplitContent/VerticalSplitContent';
import IconSplitContent from './SplitContent/IconSplitContent';
import homepageStyles from './styles.module.scss';

type Props = {
  blockItemData: any;
};

const SplitContentBlock = ({ blockItemData }: Props) => {
  const getTemplateview = (content: any, index: number) => {
    const adGridSize = 12 / content.itemsPerRow;

    if (content['@type'] === 'PreBakedContent') {
      return (
        <PrebakedSplitContent
          adGridSize={adGridSize}
          content={content}
          key={`${content.name}${index}`}
        />
      );
    }

    if (content['@type'] === 'LiveTextContent') {
      switch (content.layoutCode.toUpperCase()) {
        case 'S3HORIZ':
          return (
            <HorizontalSplitContent
              adGridSize={adGridSize}
              content={content}
              key={`${content.name}${index}`}
              index={index}
            />
          );
        case 'S3VERT':
          return (
            <VerticalSplitContent
              adGridSize={adGridSize}
              content={content}
              key={`${content.name}${index}`}
              index={index}
            />
          );
        case 'S3ICON':
          return (
            <IconSplitContent
              adGridSize={adGridSize}
              content={content}
              key={`${content.name}${index}`}
            />
          );
        default:
          return null;
      }
    }
  };

  const renderSplitView = (blockItemData: any) => {
    const itemsPerRow = blockItemData.numAds;
    const contentBlock =
      (blockItemData.adContent?.length && blockItemData.adContent[0].contents) ||
      blockItemData.adContent;
    // @ts-expect-error refine type
    return contentBlock?.map((valuesplit, index) =>
      getTemplateview({ ...valuesplit, itemsPerRow }, index)
    );
  };

  const overAllHeadLine = (blockItemData?.overAllHeadLine || '').trim();

  return (
    <DeviceWrapper
      desktop={blockItemData?.desktop || false}
      tablet={blockItemData?.tablet || false}
      mobile={blockItemData?.mobile || false}
    >
      <Grid
        className={homepageStyles.splitContentBlock}
        container
        data-testid="splitContentBlock"
        spacing={0}
      >
        {overAllHeadLine ? (
          <>
            <Grid item xs={12}>
              <h2
                className={homepageStyles.pageTitle}
                dangerouslySetInnerHTML={{
                  __html: overAllHeadLine,
                }}
              />
            </Grid>
            <Grid item xs={12} className={homepageStyles.separatorItem}>
              <div className={`${homepageStyles.separator}`} />
            </Grid>
          </>
        ) : null}
        <Grid container>{renderSplitView(blockItemData)}</Grid>
      </Grid>
    </DeviceWrapper>
  );
};

export default SplitContentBlock;
