/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as React from 'react';
import cx from 'classnames';
import azCommonStyles from '../../../theme/globals.module.scss';
import homepageStyles from '../../AdBlock/SplitContentBlock/styles.module.scss';
import ContainOverflow from '../../AZCustomComponent/ContainOverflow';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import SplitContentBlock from '../../AdBlock/SplitContentBlock';

const SplitContentBlockGeneric = ({ contentItem }: { contentItem: any }) => (
  <DeviceWrapper
    desktop={contentItem.desktop || false}
    tablet={contentItem.tablet || false}
    mobile={contentItem.mobile || false}
  >
    <div className={cx(azCommonStyles['az-margin-top-m'], azCommonStyles['az-margin-bottom-m'])}>
      <ContainOverflow>
        <div className={homepageStyles.contentBlockPaddingMobile}>
          {contentItem && <SplitContentBlock blockItemData={contentItem} />}
        </div>
      </ContainOverflow>
    </div>
  </DeviceWrapper>
);

export default SplitContentBlockGeneric;
