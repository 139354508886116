/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import { Grid, GridDirection } from '../../Grid';
import azCommonStyles from '../../../theme/globals.module.scss';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { SmartLink } from '../../../utils/smartLink';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import styles from './styles.module.scss';
import { Button } from '@/components/Button';
import { clickTrack } from '@/utils/analytics/clickTrack';
import DynamicContent from '@/components/DynamicContent';

const trackSignUpRewardsHome = () => {
  clickTrack({
    eventType: 'signUpRewardsContentBlockHome',
  });
};

const trackSignInRewardsHome = () => {
  clickTrack({
    eventType: 'signInRewardsContentBlockHome',
  });
};

type Props = {
  data: any;
  addBottomMargin?: boolean;
};

const Cta = ({
  name,
  link,
  theme,
  primary,
  description,
}: {
  name: string;
  link: string;
  theme: string;
  primary: boolean;
  description: string;
}) => {
  return name ? (
    <SmartLink className={styles.ctaLinkContainer} to={link} aria-label={`${name} ${description}`}>
      <Button
        variant={
          primary
            ? theme && theme === 'Light'
              ? 'contained'
              : 'transparent'
            : theme && theme === 'Light'
            ? 'outlined'
            : 'containedWhite'
        }
        customClass={styles.buttonSize}
        onClick={() => {
          primary ? trackSignUpRewardsHome() : trackSignInRewardsHome();
        }}
        tabIndex={-1}
        fakeButton={true}
      >
        {name}
      </Button>
    </SmartLink>
  ) : null;
};

const Headline = ({ headline }: { headline: string }) => {
  if (!headline) {
    return null;
  }

  return (
    <div
      dangerouslySetInnerHTML={(() => ({
        __html: headline,
      }))()}
    />
  );
};

export const ImageTextBlock = (props: Props) => {
  const { data, addBottomMargin } = props;
  const {
    imagePath,
    layoutCode,
    desktop,
    tablet,
    mobile,
    themeCode,
    heading: headLine,
    subHeading: subHeadLine,
    ctaLink1,
    ctaLink2,
    ctaName1,
    ctaName2,
    altText,
    text,
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  } = data;
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });
  const deviceClassName = {
    desktop: desktop || false,
    tablet: tablet || false,
    mobile: mobile || false,
  };
  const theme = themeCode;
  const imgUrl = useResponsiveImage(imagePath);
  const {
    direction,
    pictureDirection,
    containerReverse,
  }: {
    direction: GridDirection;
    pictureDirection: string;
    containerReverse: string;
  } =
    layoutCode && layoutCode === 'ImageTextLeft'
      ? {
          direction: 'row',
          pictureDirection: '',
          containerReverse: styles.containerReverse,
        }
      : {
          direction: 'row-reverse',
          pictureDirection: styles.pictureReverse,
          containerReverse: '',
        };
  return (
    <DeviceWrapper {...deviceClassName}>
      <div
        className={cx(theme && theme === 'Light' ? styles.themeLight : styles.themeDark, {
          [styles.bottomMargin]: addBottomMargin,
        })}
      >
        <Grid
          container
          spacing={0}
          direction={direction}
          className={containerReverse}
          alignItems="center"
        >
          <Grid item xs={12} md={6} lg={6} className={styles.imageContainer}>
            <img
              loading={eager ? 'eager' : 'lazy'}
              src={imgUrl}
              alt={altText}
              className={`${styles.picture} ${pictureDirection}`}
              aria-label="AutoZone Image"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={styles.textContainer}>
            <Grid item>
              <div
                className={cx(
                  azCommonStyles['az-title-1-heavy'],
                  styles.heading,
                  theme && theme === 'Light' ? styles.darkHeading : styles.lightHeading
                )}
              >
                {headLine && <DynamicContent tagName="h2" innerHtml={headLine} />}
              </div>
              <div
                className={cx(
                  azCommonStyles['az-title-1-regular'],
                  azCommonStyles['az-padding-bottom-xxxs'],
                  theme && theme === 'Light' ? styles.darkSubHeading : styles.lightSubHeading
                )}
              >
                <Headline headline={subHeadLine} />
              </div>
              <div className={styles.separator} />
            </Grid>
            <Grid
              className={cx(
                azCommonStyles['az-body-1-regular'],
                theme && theme === 'Light' ? styles.darkSubHeading : styles.lightSubHeading,
                styles.texts
              )}
            >
              <Headline headline={text} />
            </Grid>
            <Grid container className={`${styles.ctablock}`}>
              <Grid item xs={6} md={6} lg={4}>
                <Cta
                  name={ctaName1}
                  link={ctaLink1}
                  theme={theme}
                  primary={true}
                  description={altText}
                />
              </Grid>
              <Grid item xs={6} md={6} lg={4}>
                <Cta
                  name={ctaName2}
                  link={ctaLink2}
                  theme={theme}
                  primary={false}
                  description={altText}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </DeviceWrapper>
  );
};

export default ImageTextBlock;
