/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import ContainOverflow from '@/components/AZCustomComponent/ContainOverflow';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import SplitContentBlockV2 from '@/components/AdBlock/SplitContentBlockV2';
import homepageStyles from '../../AdBlock/SplitContentBlockV2/styles.module.scss';

export const SplitContentBlockV2Generic = ({ contentItem }: { contentItem: any }) => (
  <DeviceWrapper
    desktop={contentItem.desktop || false}
    tablet={contentItem.tablet || false}
    mobile={contentItem.mobile || false}
  >
    <ContainOverflow>
      <div className={cx(homepageStyles.splitContentBlockWrapper)}>
        {contentItem && <SplitContentBlockV2 blockItemData={contentItem} />}
      </div>
    </ContainOverflow>
  </DeviceWrapper>
);

export default SplitContentBlockV2Generic;
