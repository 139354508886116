/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React, { useMemo } from 'react';
import { Legal } from '../../AZCustomComponent/Legal';
import { getImagePaths } from '../../../utils/getImagePaths';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { SmartLink as Link } from '../../../utils/smartLink';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import styles from './styles.module.scss';
import { Picture } from '@/components/Picture/Picture';

type Props = {
  contentItem: {
    legalLink: string;
    legalCopy: string;
    altText: string;
    imagePath: string;
    legalLinkName: string;
    mobile: boolean;
    legalText: string;
    tablet: boolean;
    imageLink: string;
    desktop: boolean;
    name: string;
    isEagerLoadDesktop?: boolean;
    isEagerLoadMobile?: boolean;
    isEagerLoadTablet?: boolean;
  };
  width: string;
  styleOverride?: any;
};

const PreBakedBanner = ({ contentItem, styleOverride }: Props) => {
  const {
    altText,
    imagePath,
    imageLink,
    mobile,
    tablet,
    desktop,
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  } = contentItem;
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });
  const imageLoadingType = eager ? 'eager' : 'lazy';
  const imageData = useMemo(() => {
    const images = getImagePaths(imagePath);
    return {
      mobile: {
        src: images.phoneImage,
        /*
          These sizes are averages of the actual image sizes for different breakpoints.
          They are used to provide a default aspect ratio placeholder until the image
          is loaded by the browser to avoid a layout shift.
        */
        width: 1376,
        height: 550,
      },
      tablet: {
        src: images.tabletImage,
        width: 2220,
        height: 275,
      },
      desktop: {
        src: images.desktopImage,
        width: 2332,
        height: 270,
      },
    };
  }, [imagePath]);
  const imageComponent = (
    <Picture
      mobile={imageData.mobile}
      tablet={imageData.tablet}
      desktop={imageData.desktop}
      altText={altText}
      loading={imageLoadingType}
      priority={eager}
      imageClassName={styles.preBakedImage}
    />
  );

  return (
    <DeviceWrapper desktop={desktop || false} tablet={tablet || false} mobile={mobile || false}>
      <div style={styleOverride ? styleOverride : null}>
        {imageLink ? <Link to={imageLink}>{imageComponent}</Link> : imageComponent}
        <Legal content={contentItem} />
      </div>
    </DeviceWrapper>
  );
};

export default PreBakedBanner;
