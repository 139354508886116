/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import root from 'window-or-global';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import Hidden from '../../Hidden';
import NextImage, { akamaiLoader } from '@/components/NextImage';
import { Button } from '@/components/Button';
import { useResponsiveImage } from '../../../hooks/useResponsiveImage';
import { SmartLink as Link } from '../../../utils/smartLink';
import DeviceWrapper from '../../AZCustomComponent/DeviceWrapper';
import { useRouter } from 'next/router';
import { useEagerLoad } from '../../../hooks/useEagerLoad';
import { Url } from '../../../next-types';
import styles from './styles.module.scss';

type LiveTextBannerClasses = {
  root?: string;
  container?: string;
};

type Props = {
  contentItem: {
    subHeading: string;
    altText: string;
    imagePath: string;
    mobile: boolean;
    ctaLink1: Url;
    heading: string;
    ctaName1?: React.ReactNode;
    tablet: boolean;
    desktop: boolean;
    themeCode?: string;
    isEagerLoadDesktop?: boolean;
    isEagerLoadMobile?: boolean;
    isEagerLoadTablet?: boolean;
  };
  rightIcon?: string;
  classes?: LiveTextBannerClasses;
  isSuper?: boolean;
  isSuperGrid?: boolean;
};

const LiveTextBanner = ({ rightIcon, classes = {}, isSuper, contentItem, isSuperGrid }: Props) => {
  const {
    subHeading,
    altText,
    imagePath,
    mobile,
    ctaLink1,
    heading,
    ctaName1,
    tablet,
    desktop,
    themeCode,
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  } = contentItem;
  const imageUrl = useResponsiveImage(imagePath);
  const iconUrl = useResponsiveImage(rightIcon || '');
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });
  const matches = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const router = useRouter();

  const handleClick = (path: string) => {
    if (typeof path === 'string' && path.includes('://')) {
      root.open(path, '_blank');
    } else {
      router.push(path);
    }
  };

  const ContainerElement = !ctaName1 ? Link : 'div';
  const containerElementProps = !ctaName1
    ? {
        to: ctaLink1,
      }
    : {};
  return (
    <DeviceWrapper desktop={desktop || false} tablet={tablet || false} mobile={mobile || false}>
      <ContainerElement
        // @ts-expect-error refine
        to={containerElementProps.to}
        className={cx(styles.linkContainer, {
          [classes.root || '']: classes.root,
        })}
      >
        <div
          className={cx(styles.container, {
            [styles.darkTheme]: themeCode && themeCode.toLowerCase() === 'dark',
            [styles.gridContainer]: isSuperGrid,
            [classes.container || '']: classes.container,
          })}
        >
          {isSuperGrid ? (
            <div className={styles.superGrid}>
              <div
                className={
                  rightIcon
                    ? `${styles.bannerImage} ${styles.superGridBannerImage}`
                    : styles.bannerImage
                }
              >
                <NextImage
                  src={imageUrl}
                  alt={''}
                  aria-label={altText}
                  className={styles.liveTextImage}
                  priority={eager}
                  width={55}
                  height={55}
                  data-testid="liveTextImage"
                  loader={akamaiLoader}
                />
              </div>
              <div>
                <div className={`${styles.subContainer} ${styles.superSubContainer}`}>
                  <div className={`${styles.textContainer} ${styles.superGridTextContainer}`}>
                    <div
                      className={`${styles.heading} ${styles.superGridHeading}`}
                      dangerouslySetInnerHTML={(() => ({
                        __html: heading,
                      }))()}
                    />
                    <div
                      className={`${styles.subHeading} ${styles.superGridSubHeading}`}
                      dangerouslySetInnerHTML={(() => ({
                        __html: subHeading,
                      }))()}
                    />
                  </div>
                  {ctaName1 && (
                    <div className={styles.ctaButtonContainer}>
                      <Button
                        // @ts-expect-error refine
                        onClick={() => handleClick(ctaLink1)}
                        customClass={`${styles.ctaButton} ${
                          themeCode && themeCode.toLowerCase() === 'dark'
                            ? styles.darkThemeButton
                            : ''
                        }`}
                        rightArrow={matches}
                        variant={matches ? 'ghost' : ''}
                      >
                        {ctaName1}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={styles.bannerImage}>
                <NextImage
                  src={imageUrl}
                  alt={''}
                  priority={eager}
                  aria-label={altText}
                  data-testid="liveTextImage"
                  className={styles.liveTextImage}
                  width={55}
                  height={55}
                  loader={akamaiLoader}
                />
              </div>

              <div
                className={
                  isSuper
                    ? `${styles.subContainer} ${styles.superSubContainer}`
                    : styles.subContainer
                }
              >
                <div
                  className={
                    isSuper
                      ? `${styles.textContainer} ${styles.superTextContainer}`
                      : styles.textContainer
                  }
                >
                  <div
                    className={
                      isSuper ? `${styles.heading} ${styles.superHeading}` : styles.heading
                    }
                    dangerouslySetInnerHTML={(() => ({
                      __html: heading,
                    }))()}
                  />
                  <Hidden smDown={isSuper ? false : true}>
                    <div
                      className={
                        isSuper
                          ? `${styles.subHeading} ${styles.superSubHeading}`
                          : styles.subHeading
                      }
                      dangerouslySetInnerHTML={(() => ({
                        __html: subHeading,
                      }))()}
                    />
                  </Hidden>
                </div>
                {ctaName1 && (
                  <div className={styles.ctaButtonContainer}>
                    <Button
                      // @ts-expect-error refine
                      onClick={() => handleClick(ctaLink1)}
                      customClass={`${styles.ctaButton} ${
                        themeCode && themeCode.toLowerCase() === 'dark'
                          ? styles.darkThemeButton
                          : ''
                      }`}
                      rightArrow={matches}
                      variant={matches ? 'ghost' : ''}
                    >
                      {ctaName1}
                    </Button>
                  </div>
                )}
              </div>
              {rightIcon ? (
                <div className={styles.superIcon}>
                  <NextImage
                    src={iconUrl}
                    alt=""
                    priority={eager}
                    className={styles.liveTextImage}
                    data-testid="liveTextRightIcon"
                    width={32}
                    height={32}
                    loader={akamaiLoader}
                  />
                </div>
              ) : null}
            </>
          )}
        </div>
      </ContainerElement>
    </DeviceWrapper>
  );
};

export default LiveTextBanner;
