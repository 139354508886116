/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { Grid } from '../../../Grid';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import homepageStyles from '../styles.module.scss';
import { Legal } from '../../../AZCustomComponent/Legal';
import { useResponsiveImage } from '../../../../hooks/useResponsiveImage';
import { useEagerLoad } from '../../../../hooks/useEagerLoad';

type Props = {
  adGridSize: number;
  content: any;
  index: number;
};

export const HorizontalSplitContent = ({
  adGridSize,
  content: { isEagerLoadDesktop, isEagerLoadMobile, isEagerLoadTablet, ...content },
  index,
}: Props) => {
  const imageUrl = useResponsiveImage(content.imagePath);
  const eager = useEagerLoad({
    isEagerLoadDesktop,
    isEagerLoadMobile,
    isEagerLoadTablet,
  });
  return (
    <Grid item xs={12} md={adGridSize}>
      <Grid item xs={12} className={homepageStyles.leftOrangeitem_content}>
        {content && (
          <Link to={content.ctaLink1 || content.ctaLink2 || '#'} key={`${content.name}${index}`}>
            <div
              className={cx(
                homepageStyles.split3ItemContent,
                azCommonStyles['az-margin-bottom-xxs']
              )}
            >
              {imageUrl && (
                <div className={homepageStyles.split3ItemImg}>
                  <img src={imageUrl} loading={eager ? 'eager' : 'lazy'} alt="" />
                </div>
              )}
              <div
                className={cx(
                  homepageStyles.split3ItemTextcontent,
                  azCommonStyles['az-padding-xxs']
                )}
              >
                {content.heading && (
                  <h3
                    className={cx(
                      homepageStyles.leftSplitHeading,
                      azCommonStyles['az-title-2-heavy'],
                      azCommonStyles['az-margin-bottom-4xs']
                    )}
                    dangerouslySetInnerHTML={{ __html: content.heading }}
                  />
                )}
                {content.subHeading && (
                  <div
                    className={cx(
                      homepageStyles.leftSplitSubHeading,
                      azCommonStyles['az-title-5-heavy']
                    )}
                    dangerouslySetInnerHTML={{ __html: content.subHeading }}
                  />
                )}
                {content.promoMessage && (
                  <div
                    className={cx(
                      homepageStyles.leftSplitPara,
                      azCommonStyles['az-body-2-regular']
                    )}
                    dangerouslySetInnerHTML={{ __html: content.promoMessage }}
                  />
                )}
                {content.ctaName && (
                  <div
                    className={cx(
                      homepageStyles.leftSplitPara,
                      azCommonStyles['az-body-2-regular']
                    )}
                    dangerouslySetInnerHTML={{ __html: content.ctaName }}
                  />
                )}
              </div>
            </div>
          </Link>
        )}
        <Legal content={content} />
      </Grid>
    </Grid>
  );
};

export default HorizontalSplitContent;
