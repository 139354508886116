/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { Grid } from '@/components/Grid';
import { useResponsiveImage } from '@/hooks/useResponsiveImage';
import { memo } from 'react';
import { Legal } from '@/components/AZCustomComponent/Legal';
import LEFTH2C3 from './LeftH2C3/index';
import homepageStyles from './styles.module.scss';
import type { HeroLiveText } from '@/types/legacy';

type Props = {
  blockItem: HeroLiveText;
  itemIndex: number;
  styleOverride?: React.CSSProperties;
};

const HeroTemplate = memo<Props>(function HeroTemplate(props: Props) {
  const { blockItem, itemIndex } = props;
  const deviceClassName = {
    desktop: blockItem.desktop || false,
    tablet: blockItem.tablet || false,
    mobile: blockItem.mobile || false,
  };

  const imagePath = useResponsiveImage(blockItem.backGroundImage);
  return (
    <DeviceWrapper {...deviceClassName}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={12} md={12}>
          <div
            style={{
              backgroundImage: `url(${imagePath})`,
              backgroundSize: 'cover',
              maxWidth: '1600px',
              width: '100%',
            }}
            className={`${homepageStyles.banner_content}`}
          >
            <div>
              <div className={`${homepageStyles.content_part_desk}`}>
                <div className={homepageStyles.display_prop}>
                  <LEFTH2C3 {...blockItem} itemIndex={String(itemIndex)} />
                </div>
              </div>
            </div>
          </div>
          <Grid item xs={12} lg={12} md={12}>
            <div
              className={homepageStyles.bannerContainer}
              style={props.styleOverride ? props.styleOverride : undefined}
            >
              <Legal content={blockItem} />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </DeviceWrapper>
  );
});

export default HeroTemplate;
