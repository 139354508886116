/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import * as React from 'react';
import cx from 'classnames';
import ContainOverflow from '../../AZCustomComponent/ContainOverflow';
import PreBakedBanner from '../../ContentBlocks/ShelfBanner/PreBakedBanner';
import styles from './styles.module.scss';

type ShelfBannerPreBakedGenericClasses = {
  root?: string;
};

const ShelfBannerPreBakedGeneric = ({
  keyProp,
  contentItem,
  contentType,
  index,
  classes,
}: {
  keyProp: string;
  contentItem: any;
  contentType: string;
  index: number;
  classes?: ShelfBannerPreBakedGenericClasses;
}) => (
  <div
    data-testid={`topHtmlBanner${index}`}
    className={cx(styles.topHtmlBanner, classes?.root)}
    key={keyProp}
  >
    <ContainOverflow fullWidth>
      {/* @ts-expect-error TODO: error surfaced after unwrapping from HOC, DFS-109 */}
      <PreBakedBanner
        contentItem={contentItem}
        styleOverride={contentType === 'SuperHeroRightPreBaked' ? { margin: '0' } : null}
      />
    </ContainOverflow>
  </div>
);

export default ShelfBannerPreBakedGeneric;
