/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Legal } from '@/components/AZCustomComponent/Legal';
import Carousel from '@/components/Carousel';
import { Grid } from '@/components/Grid';
import DeviceWrapper from '@/components/AZCustomComponent/DeviceWrapper';
import { trackHomeCarouselInteraction } from '@/utils/analytics/track/trackHomeCarouselInteraction';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { isMobile } from '@/utils/common';
import { useEffect, useRef, useState } from 'react';
import HeroTemplate from './HeroTemplate';
import PreBakedHero from './PreBakedHero';
import styles from './styles.module.scss';
import { useInteractionCheck } from '@/hooks/useInteractionCheck';
import type {
  HeroCarouselSlotContent,
  HeroCarouselSlotContentsItem,
  DeviceOptions,
  HeroLiveText,
  HeroPreBaked,
} from '@/types/legacy';

type Props = {
  data: HeroCarouselSlotContent['contents'];
  styleOverride?: React.CSSProperties | undefined;
  home?: boolean | null;
  isExternalLegalCopyModalOpened?: boolean;
  getSelectedData?: (data: HeroCarouselSlotContentsItem | HeroLiveText) => void;
  showLegalContent?: boolean;
};

const showSlides = (colItem: { showSlide?: boolean } & DeviceOptions) =>
  colItem.showSlide ||
  (isMobile() && (colItem.mobile || colItem.tablet)) ||
  (!isMobile() && colItem.desktop);

const HeroComponentStack = ({
  data,
  styleOverride,
  home,
  isExternalLegalCopyModalOpened = false,
  getSelectedData,
  showLegalContent = true,
}: Props) => {
  const justMounted = useRef(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [startAutoRotation, setStartAutoRotation] = useState(false);
  const [setFocusToAnchorTag, setSetFocusToAnchorTag] = useState(false);

  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [isLegalCopyModalOpened, setIsLegalCopyModalOpened] = useState(() => false);

  // Only enable carousel autorotate when a user interacts with the site
  const userInteracted = useInteractionCheck();

  useEffect(() => {
    if (justMounted.current) {
      justMounted.current = false;
      if (getSelectedData && data.length) {
        getSelectedData(data[0]);
      }
      return;
    }

    setStartAutoRotation(
      !isLegalCopyModalOpened && userInteracted && !isExternalLegalCopyModalOpened
    );
  }, [
    isLegalCopyModalOpened,
    userInteracted,
    isExternalLegalCopyModalOpened,
    data,
    getSelectedData,
  ]);

  useEffect(() => {
    startAutoRotation && setFocusToAnchorTag && setSetFocusToAnchorTag(false);
  }, [setFocusToAnchorTag, startAutoRotation]);

  const stopRotate = () => {
    if (startAutoRotation) {
      setStartAutoRotation(false);
    }
  };
  const startRotate = () => {
    if (!startAutoRotation) {
      setStartAutoRotation(true);
    }
  };

  const handleDotClick = (index: number, isKeyUp: boolean) => {
    setCurrentSlide(index);
    getSelectedData?.(data[index]);
    const clickedData = data[index];

    trackHomeCarouselInteraction(clickedData.altText, index, `Home Hero Bubble: Rank ${index}`);

    isKeyUp ? setSetFocusToAnchorTag(true) : setSetFocusToAnchorTag(false);
  };

  const handleImageChange = (index: number) => {
    setCurrentSlide(index);
    getSelectedData?.(data[index]);
  };

  const handleToggleLegalModal = (isOpened: boolean) => {
    setIsLegalCopyModalOpened(isOpened);
  };

  const renderHeroContentBlock = () => {
    return data.map(
      (colItem, index) =>
        showSlides(colItem) &&
        ('backGroundImage' in colItem && colItem[`@type`].includes('LiveText') ? (
          <HeroTemplate
            key={`${index}${'name' in colItem ? colItem.name : ''}`}
            blockItem={colItem as unknown as HeroLiveText}
            itemIndex={index}
            styleOverride={!isPhone ? styleOverride : undefined}
          />
        ) : (
          colItem[`@type`].includes('PreBaked') && (
            <PreBakedHero
              key={`${index}${'name' in colItem ? colItem.name : ''}`}
              blockItem={colItem as unknown as HeroPreBaked}
              itemIndex={index}
              tabindex={0}
              linkEnabled={currentSlide === index}
              setFocusToAnchorTag={setFocusToAnchorTag}
              styleOverride={!isPhone ? styleOverride : undefined}
            />
          )
        ))
    );
  };

  const getBlockItembyId = (id: number) => {
    return data.find((item, index) => {
      if (index == id) {
        return item;
      }
    });
  };

  const renderLegalComponent = () => {
    const blockItem = getBlockItembyId(currentSlide);
    const legalText =
      blockItem !== undefined && 'legalText' in blockItem ? blockItem.legalText : undefined;
    const isWithLegalText = Boolean(legalText);
    const deviceClassName = {
      desktop: blockItem?.desktop || false,
      tablet: blockItem?.tablet || false,
      mobile: blockItem?.mobile || false,
    };

    return (
      (isPhone || showLegalContent) && (
        <DeviceWrapper {...deviceClassName}>
          <div className={styles.legacyBanner}>
            <Legal
              content={blockItem}
              handleToggleLegalModal={handleToggleLegalModal}
              isWithLegalText={isWithLegalText}
              isHeroCarouselBanner={!!home}
            />
          </div>
        </DeviceWrapper>
      )
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12}>
        <div className={styles.containerSlider}>
          <div
            onMouseEnter={stopRotate}
            onFocus={stopRotate}
            onMouseLeave={!isPhone && !isLegalCopyModalOpened ? startRotate : undefined}
            onBlur={!isLegalCopyModalOpened ? startRotate : undefined}
            className={styles.slider}
          >
            <Carousel
              onImageChange={handleImageChange}
              autoRotationEnabled={startAutoRotation}
              handleDotClick={handleDotClick}
              showCarouselDots
            >
              {renderHeroContentBlock()}
            </Carousel>
            {renderLegalComponent()}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default HeroComponentStack;
