/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import cx from 'classnames';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import { Legal } from '../../../AZCustomComponent/Legal';
import { Button } from '@/components/Button';
import { useEagerLoad } from '@/hooks/useEagerLoad';
import NextImage, { pureLoader } from '@/components/NextImage';
import homepageStyles from '../styles.module.scss';
import { useResponsiveImage } from '@/hooks/useResponsiveImage';
import { useMediaQuery } from '@/hooks/useMediaQuery';

type Props = {
  content: any;
  index: number;
  itemWidth: number;
};

export const ImageBakedSplitContent = ({ content, index, itemWidth }: Props) => {
  const imageUrl = useResponsiveImage(content.imagePath);
  const eager = useEagerLoad({
    isEagerLoadDesktop: content.isEagerLoadDesktop,
    isEagerLoadMobile: content.isEagerLoadMobile,
    isEagerLoadTablet: content.isEagerLoadTablet,
  });
  const imageLink = content.imageLink;
  const imageIsLink = imageUrl && !!imageLink;
  const ImageContainer = imageIsLink ? Link : 'div';
  const imageContainerProps: any = imageIsLink
    ? {
        to: imageLink,
      }
    : {};
  const matchesMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const customClass = itemWidth > 1 && matchesMobile ? homepageStyles.ctaButton : '';
  const hasCTAButton = content.ctaButtonName && content.ctaButtonURL;

  return content && imageUrl ? (
    <div
      key={`${content.name}${index}`}
      data-testid={`image-baked-content-${content.name.toLowerCase().replace(/\s/g, '-')}-${index}`}
      className={hasCTAButton ? homepageStyles.imageBakedContainer : undefined}
      style={{ width: `calc(${100 / itemWidth}% - 18px)` }}
    >
      {content.topHeadingContent && (
        <div
          className={cx(azCommonStyles['az-margin-bottom-xxs'])}
          dangerouslySetInnerHTML={{ __html: content.topHeadingContent }}
        />
      )}
      <ImageContainer {...imageContainerProps} className={cx(homepageStyles.imageContainer)}>
        <NextImage
          className={cx(homepageStyles.bakedImage)}
          src={imageUrl}
          alt={content.altText}
          layout="fill"
          /*
           * because we have the bad image in order to have the layout formatted correctly,
           * we need to add the onError function to hide the broken image/red box on firefox and ie
           */
          // @ts-expect-error investigate if style is always there
          onError={(e) => (e.target.style.display = 'none')}
          loader={pureLoader}
          priority={eager}
        />
      </ImageContainer>
      {content.bottomHeadingContent && (
        <div
          className={cx(azCommonStyles['az-margin-top-xxs'])}
          dangerouslySetInnerHTML={{ __html: content.bottomHeadingContent }}
        />
      )}
      {hasCTAButton && (
        <div
          className={cx(
            azCommonStyles['az-margin-top-xxs'],
            itemWidth > 1 ? homepageStyles.imageBakedCTAButton : ''
          )}
        >
          <Link
            to={content.ctaButtonURL}
            samePage
            ariaLabel={`${content.altText} ${content.ctaButtonName}`}
          >
            <Button customClass={customClass} variant="contained" fakeButton={true}>
              {content.ctaButtonName}
            </Button>
          </Link>
        </div>
      )}
      {content.ctaLinkName && content.ctaLinkURL && (
        <div
          className={cx(
            azCommonStyles['az-margin-top-xxs'],
            itemWidth > 1 ? homepageStyles.imageBakedCTAButton : ''
          )}
        >
          <Link
            to={content.ctaLinkURL}
            samePage
            className={homepageStyles.imageBakedLink}
            ariaLabel={`${content.altText} ${content.ctaLinkName}`}
          >
            {content.ctaLinkName}
          </Link>
        </div>
      )}
      <Legal overrideClassName={homepageStyles.legalTextWrapperItem} content={content} />
    </div>
  ) : null;
};

export default ImageBakedSplitContent;
