/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import cx from 'classnames';
import root from 'window-or-global';
import { Grid } from '../../../Grid';
import { SmartLink as Link } from '../../../../utils/smartLink';
import azCommonStyles from '../../../../theme/globals.module.scss';
import homepageStyles from '../styles.module.scss';
import { Legal } from '../../../AZCustomComponent/Legal';
import { useResponsiveImage } from '../../../../hooks/useResponsiveImage';
import { clickTrack } from '@/utils/analytics/clickTrack';

const trackBuyNowLinkContentBlock = (msg: string) => {
  const prodName =
    msg
      .replace(/<[^>]+>/gi, '')
      .replace(/&nbsp;/gi, '')
      .trim() || '';
  const data = {
    pageName: root.utag_data?.pageName,
    eventType: 'buynowLinkcontentblock',
    productName: prodName,
  };
  clickTrack(data);
};

type Props = {
  adGridSize: number;
  content: any;
  index: number;
};

export const VerticalSplitContent = ({ adGridSize, content, index }: Props) => {
  const imageUrl = useResponsiveImage(content.imagePath);

  if (imageUrl) {
    return (
      <Grid container spacing={2} direction={'column'} item xs={12} md={adGridSize}>
        <Grid item>
          <Link to={content.ctaLink1 || content.ctaLink2 || '#'} key={`${content.name}${index}`}>
            <div
              className={`${homepageStyles.splitImageBlock}`}
              style={{
                background: `url(${imageUrl}) center right / cover no-repeat`,
              }}
            >
              {content.heading && (
                <h3
                  className={cx(
                    homepageStyles.splitHeadline,
                    azCommonStyles['az-display-1-heavy'],
                    azCommonStyles['az-margin-top-zero'],
                    azCommonStyles['az-margin-bottom-4xs']
                  )}
                  dangerouslySetInnerHTML={{ __html: content.heading }}
                />
              )}
              {content.subHeading && (
                <h6
                  className={cx(
                    homepageStyles.splitSubHeadLine,
                    azCommonStyles['az-title-5-heavy'],
                    azCommonStyles['az-margin-top-4xs'],
                    azCommonStyles['az-margin-bottom-4xs']
                  )}
                  dangerouslySetInnerHTML={{ __html: content.subHeading }}
                />
              )}
              {content.promoMessage && (
                <div
                  className={cx(homepageStyles.splitBodyText, azCommonStyles['az-body-2-regular'])}
                  dangerouslySetInnerHTML={{ __html: content.promoMessage }}
                />
              )}
              {content.cTAURL && (
                <Link
                  className={cx(homepageStyles.splitActionBtn, azCommonStyles['az-button-text'])}
                  to={content.cTAURL}
                  onClick={() => {
                    trackBuyNowLinkContentBlock(content.subHeading);
                  }}
                >
                  {content.cTAName && <p>{content.cTAName}</p>}
                </Link>
              )}
            </div>
          </Link>
        </Grid>
        <Grid item>
          <Legal content={content} />
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default VerticalSplitContent;
